<template>
    <v-stepper-content step="2" class="py-0 px-2">
        <v-row class="mt-2">
            <v-col cols="12 py-0">
                <p class="headline mb-2" v-show="!isAddPassengerMode">Gegevens passagiers</p>
                <p class="headline mb-2" v-show="isAddPassengerMode">Gegevens passagier</p>
                <div class="text-start" v-show="!isAddPassengerMode">Indien er passagiers met u meerijden kunt u die hier aanmelden.</div>
                <div class="text-start" v-show="isAddPassengerMode">Vul hier de gegevens in van de passagier. Wilt u nog iemand toevoegen? Herhaal deze stap wanneer u de huidige passagier hebt opgeslagen.</div>
            </v-col>
            <v-col cols="12 py-0" v-for="(item, index) in orderDetails.registeredPassengers" :key="index">
                <v-expand-transition>
                    <div v-show="!item.isActive" >
                        <v-list-item class="px-0 pt-1">
                            <v-list-item-avatar width="60" height="60">
                                <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black" @click="setActiveItem()">{{getPersonInitials(item.firstname, item.lastname)}}</div>
                            </v-list-item-avatar>
                            <div class="rounded-circle edit-container" @click="item.isActive = true">
                                <img class="edit-icon" :src="editIcon" alt="Edit" />
                            </div>
                            <v-list-item-content class="pb-0">
                                <div class="d-flex">
                                    <div class="pr-3 subtitle-1">{{fullname(item)}} <span v-if="item.phone">{{` (${item.phone})`}} </span></div>
                                </div>
                                <div class="d-flex">
                                    <div class="pr-3 subtitle-1">{{formatDateWithoutTime(item.birthdate)}}</div>
                                </div>
                                <div class="d-flex">
                                    <v-checkbox v-model="item.hasLunch" class="ma-0 pt-0" :hide-details="true" v-if="roadtrip.packedLunchPrice != 0">
                                        <template v-slot:label>
                                            <div>
                                            Lunchpakket
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox v-model="item.hasDinner" class="ml-5 ma-0 pt-0" :hide-details="true" v-if="roadtrip.dinnerPrice != 0">
                                        <template v-slot:label>
                                            <div>
                                            Diner
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-list-item-content>

                            <img class="delete-icon" :src="deleteIcon" alt="Delete" @click="showDialog(item, index)" />

                        </v-list-item>
                        <div class="pb-3">
                            Allergieën en voorkeuren: <span class="text-lowercase">{{getFoodRestrictions(item.allergies)}}</span>
                        </div>
                        <v-divider color="white"></v-divider>
                    </div>
                </v-expand-transition>

                <v-expand-transition>
                    <div v-show="item.isActive">
                        <v-row class="mt-0">
                            <v-col>
                                <p class="headline mb-2">Persoonsgegevens</p>
                            </v-col>
                        </v-row>
                        <v-form ref="form" lazy-validation v-model="valid">
                            <v-text-field 
                                v-model="item.firstname"
                                label="Voornaam*"
                                @keypress="isNonNumeric($event)"
                                :rules="[rules.required, rules.nameLength, rules.validName]"
                                required />
                            <v-text-field 
                                v-model="item.lastname"
                                label="Achternaam*"
                                @keypress="isNonNumeric($event)"
                                :rules="[rules.required, rules.nameLength, rules.validName]"
                                required />
                            <v-text-field 
                                v-model="item.phone"
                                @keypress="isPhoneNumberKey($event)"
                                :rules="[rules.phoneNumberLength]"
                                label="Telefoonnummer"  />
                            <div class="mb-3">
                                <DatepickerFormatted :label="`Geboortedatum`" :isRequired="false" :date="item.birthdate" @changed="item.birthdate = $event" :showIcon="false" :isBirthdate="true" />
                            </div>

                            <FoodRestrictionDropdown :allergies="item.allergies" @changed="item.allergies = $event" />
                            <v-col cols="12" class="d-inline-flex mt-2 pa-0">
                                <v-checkbox v-model="item.hasLunch" class="ma-0 pt-3" :hide-details="true" v-if="roadtrip.packedLunchPrice != 0">
                                    <template v-slot:label>
                                        <div>
                                        Lunchpakket
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex mt-2 pa-0">
                                <v-checkbox v-model="item.hasDinner" class="ma-0 pt-3" :hide-details="true" v-if="roadtrip.dinnerPrice != 0">
                                    <template v-slot:label>
                                        <div>
                                        Diner
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-btn color="primary" block @click="savePerson(item)" class="mt-6">
                                Opslaan
                            </v-btn>
                        </v-form>
                        <v-row class="justify-center text-center" no-gutters>
                            <v-card-actions>
                                <v-container >
                                    <v-row dense>
                                        <v-col cols="12">
                                            Geen persoon toevoegen?
                                            <span class="link" @click="cancel(item, index)"> 
                                                Annuleer.
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-actions>
                        </v-row>
                    </div>
                </v-expand-transition>
            </v-col>
            <v-expand-transition>
                <v-col cols="12" v-show="!isAddPassengerMode">
                    <v-btn color="primary" block class="my-5" @click="addNewPassenger()">
                        Voeg nieuwe passagier toe
                    </v-btn>
                    <v-btn color="primary" block
                        @click="validate">
                        Volgende
                    </v-btn>
                    <v-row class="justify-center text-center" no-gutters>
                        <v-card-actions>
                            <v-container>
                                <v-row dense>
                                        <v-col cols="12">
                                        Foutje gemaakt?
                                        <span class="link" @click="previous">Ga terug.</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-row>
                </v-col>
            </v-expand-transition>
        </v-row>
        <v-dialog v-if="selectedItem"
            v-model="dialog"
            max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Bevestig verwijderen
                </v-card-title>

                <v-card-text>
                    Weet u zeker dat u "{{ fullname(selectedItem) }}" wilt verwijderen?
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialog = false">
                    Annuleren
                </v-btn>

                <v-btn
                    text
                    @click="deletePerson()">
                    Verwijderen
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-stepper-content>
</template>

<script>

module.exports = {
    props: {
        next: Function,
        previous: Function,
        formatDateWithoutTime: Function,
        getFoodRestrictions: Function,
        getPersonInitials: Function,
        isPhoneNumberKey: Function,
        isNonNumeric: Function,
        rules: Object,
        roadtrip:Object,
        orderDetails: Object
    },
    beforeCreate: function () {
        this.$options.components.FoodRestrictionDropdown = require('@/components/common/options/FoodRestrictionDropdown').default,
        this.$options.components.DatepickerFormatted = require('@/components/common/DatepickerFormatted').default
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        deleteIcon: require('@/assets/icons/delete.svg'),
        valid: false,
        showOptions: false,
        selectedItem: null,
        selectedIndex: -1,
        dialog: false
    }),
    computed:{
        isAddPassengerMode()
        {
            let addNewPassenger = this.orderDetails.registeredPassengers.filter(x => x.isNew);
            return addNewPassenger.length > 0;
        }
    },
    methods:
    {
        fullname(item)
        {
            if(item == null)
            {
                return "";
            }

            return `${item.firstname} ${item.lastname}`;
        },
        validate()
        {
            // continue to next
            this.next();
        },
        savePerson(item)
        {
            //const valid = this.$refs.form.validate();
            const valid = item.lastname != "" && item.firstname != "";
            if(valid)
            {
                item.isActive = false;
                item.isNew = false;
            }
            
        },
        addNewPassenger()
        {
            this.orderDetails.registeredPassengers.push({
                firstname: "", 
                lastname: "", 
                phone: "",
                hasBreakfast: false,
                hasLunch: false,
                hasDinner: false,
                hasHotel: false,
                allergies: [],
                isActive: true,
                isNew: true
            });
        },
        cancel(item, index)
        {
            if (item.isNew)
            {
                this.$delete(this.orderDetails.registeredPassengers, index);
            }
            else
            {
                item.isActive = false;
            }
        },
        deletePerson()
        {
            this.$delete(this.orderDetails.registeredPassengers, this.selectedIndex);
            this.selectedItem.isActive = false;
            this.dialog = false;
        },
        showDialog(item, index)
        {
            this.selectedItem = item;
            this.selectedIndex = index;
            this.dialog = true;
        },
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-container .edit-icon
{
    width: 12px;
    height: 11px;
}

.edit-container
{
    background: #0071e3;
    position: absolute;
    bottom: 6px;
    left: 40px;
    padding-left: 7px;
    padding-right: 6px;
    cursor: pointer;
}

.delete-icon 
{
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer;
}

.initials-avatar
{
    background: white;
    color: #181717;
    font-size: 26px;
    cursor: pointer;
}

</style>
