
import { Person } from './Person';

export class Driver extends Person {
    email = '';
    password = '';

    // order options
    // TODO JS (TASK ID: 329): Move to new class
    hasBreakfast = false;
    hasLunch = false;
    hasDinner = false;
    hasHotel = false;

    constructor() {
        super();
    }
}
  