<template>
    <div>
        <v-select 
            v-model="txt_country"
            :items="countries"
            hide-details="true"
            offset-y
            item-text="item2"
            item-value="item1"
            auto-select-first
            :readonly="txt_isReadOnly"
            :label="label"
            dense></v-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
export default {
    name: 'CountryDropdown',
    props: 
    {
        country: Number,
        isReadOnly: Boolean,
        hideLabel: Boolean
    },
    mixins: [DataMixin, MenuHelperMixin],
    beforeMount()
    {
        this.txt_country = this.country;
        this.txt_isReadOnly = this.isReadOnly  ? this.isReadOnly : false;
        this.label = this.hideLabel ? "" : "Land";

    },
    data() {
        return {
            txt_country: 0,
            txt_isReadOnly: false,
            label: ''
        }
    },
    computed: {
        ...mapState({
            countries: state => state.menuHelper.countries
        })
    },
    watch: {
        country: {
            deep: true,
            handler() {
                this.txt_country = this.country;
            }
        },
        isReadOnly: {
            deep: true,
            handler() {
                this.txt_isReadOnly = this.isReadOnly;
            }
        },
        txt_country: 'onValueChanged'
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_country);
        }
    }
}
</script>