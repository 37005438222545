<template>
    <v-checkbox v-model="data.acceptTerms" class="mt-0"
        :rules="[rules.agreeTerms]">
        <template v-slot:label>
            <div>
                Ik ga akkoord met de
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <a
                        style="color:#76baff"
                        target="_blank"
                        href="https://www.roadpursuit.com/data/algemene-voorwaarden-roadpursuit.pdf"
                        @click.stop
                        v-on="on">
                        algemene voorwaarden
                    </a>
                    </template>
                    Opent nieuw tabblad
                </v-tooltip>
                en
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a
                            style="color:#76baff"
                            target="_blank"
                            href="https://roadpursuit.com/data/privacyverklaring-roadpursuit.pdf"
                            @click.stop
                            v-on="on">
                            privacyverklaring</a>
                    </template>
                        Opent nieuw tabblad
                </v-tooltip>.
            </div>
        </template>
    </v-checkbox>
</template>
<script>
import DataMixin from "@/mixins/data.mixin";

export default {
    name: 'TermsAndConditions',
    props: {
        data: Object
    },
    mixins: [DataMixin],
}
</script>
