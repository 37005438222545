<template>
    <!-- Extra information -->
    <div class="pb-3">
        <v-row no-gutters>
            <v-col cols="12" class="px-0">
                <span class="header-title font-weight-medium">Extra informatie</span>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" class="ma-auto py-2 px-0">
                <div>
                    <v-text-field
                        hide-details="true"
                        v-model="orderDetails.notes"
                        label="Notitie"
                        outlined></v-text-field>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'OrderNotes',
    props: {
        orderDetails: Object,
    },
    data: () => ({
     
    }),
}
</script>
