<template>
    <v-stepper-content step="1" class="py-0 px-2">
        <p class="headline">Gegevens bestuurder</p>

        <v-form :ref="'stepForm'" lazy-validation>
            <v-text-field v-model="orderDetails.driver.email"
                :rules="[rules.required, rules.email]"
                label="E-mailadres*"
                required />

            <v-text-field
                v-if="orderDetails.isNewAccount"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.password]"
                color="primary"
                :type="showPassword ? 'text' : 'password'"
                label="Wachtwoord*"
                v-model="orderDetails.driver.password"
                @click:append="showPassword = !showPassword"
                required>
                </v-text-field>

            <v-text-field v-model="orderDetails.driver.firstname"
                @keypress="isNonNumeric($event)"
                :rules="[rules.required, rules.nameLength, rules.validName]"
                label="Voornaam*"
                required />

            <v-text-field v-model="orderDetails.driver.lastname"
                @keypress="isNonNumeric($event)"
                :rules="[rules.required, rules.nameLength, rules.validName]"
                label="Achternaam*"
                required />

            <div class="mb-3">
                <DatepickerFormatted :label="`Geboortedatum*`" 
                @changed="orderDetails.driver.birthdate = $event" 
                :isRequired="true" 
                :date="orderDetails.driver.birthdate" 
                :showIcon="false" 
                :isAccountHolder="true"
                :isBirthdate="true" />
            </div>

            <v-text-field v-model="orderDetails.driver.phone"
                label="Telefoonnummer" 
                @keypress="isPhoneNumberKey($event)"
                :rules="[rules.phoneNumberLength]"
                @keydown.native.enter="validate" />

            <div class="mb-2">
                <FoodRestrictionDropdown :allergies="orderDetails.driver.allergies" @changed="orderDetails.driver.allergies = $event" />
            </div>

            <v-col cols="12" class="d-inline-flex pa-0" v-if="roadtrip.packedLunchPrice != 0">
                <v-checkbox v-model="orderDetails.driver.hasLunch" class="ma-0 pt-3" :hide-details="true">
                    <template v-slot:label>
                        <div>
                        Lunchpakket
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col cols="12" class="d-inline-flex pa-0" v-if="roadtrip.dinnerPrice != 0">
                <v-checkbox v-model="orderDetails.driver.hasDinner" class="ma-0 pt-3" :hide-details="true">
                    <template v-slot:label>
                        <div>
                        Diner
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-form>

        <v-btn color="primary" block class="mt-6"
            @click="validate">
            Volgende
        </v-btn>
    </v-stepper-content>
</template>

<script>
module.exports = {
    props: {
        next: Function,
        rules: Object,
        roadtrip:Object,
        isPhoneNumberKey: Function,
        isNonNumeric: Function,
        orderDetails: Object
    },
    beforeCreate: function () {
        this.$options.components.FoodRestrictionDropdown = require('@/components/common/options/FoodRestrictionDropdown').default,
        this.$options.components.DatepickerFormatted = require('@/components/common/DatepickerFormatted').default
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        activePicker: null,
        menu: false,
        showPassword: false,
    }),
    watch: {
        menu (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    methods:
    {
        save (date) {
            this.$refs.menu.save(date)
        },
        validate()
        {
            let v = this.$refs.stepForm.validate()
            if (v) {
                // continue to next
                this.next();
            }
        }
    }
}
</script>
<style scoped>
.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.header
{
    display: inline-flex;
}
</style>