<template>
    <v-menu
        :menu-props="{ contentClass: 'datepicker-container' }"
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFormatted"
                    :label="txt_label"
                    hint="DD-MM-YYYY format"
                    placeholder="DD-MM-YYYY"
                    persistent-hint
                    v-bind="attrs"
                    :prepend-icon="showIcon ? 'mdi-calendar-blank' : undefined"
                    :rules="pickerRules"
                    :required="isRequired"
                    :disabled="txt_isDisabled"
                    @blur="txt_date = parseDate(dateFormatted)"
                    v-on="on"></v-text-field>
            </template>
        <v-date-picker
            v-model="txt_date"
            no-title
            :active-picker.sync="activePicker"
            :min="txt_isBirthdate ? minBirthdate() : txt_minDate"
            :max="txt_isBirthdate ? maxBirthdate(age_limit) : undefined"
            :readonly="txt_isReadOnly"
            @input="menu = false"
        ></v-date-picker>
    </v-menu>
</template>
<script>
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
export default {
    name: 'DatepickerFormatted',
    props: {
        date: String,
        label: String,
        isRequired: Boolean,
        isReadOnly: Boolean,
        isDisabled: Boolean,
        showIcon: Boolean,
        isBirthdate: Boolean,
        isAccountHolder: Boolean,
        minDate: String
    },
    mixins: [FormatMixin, DataMixin],
    beforeMount()
    {
        this.txt_date = this.date;
        this.txt_minDate = this.minDate != "" ? this.minDate : undefined;
        this.txt_label = this.label != "" ? this.label : "Date";
        this.txt_isRequired = this.isRequired;
        this.txt_isReadOnly = this.isReadOnly;
        this.txt_isDisabled = this.isDisabled;
        this.txt_isBirthdate = this.isBirthdate;
        this.age_limit = this.isAccountHolder ? this.account_age_limit : 0; // no age restrictions for datepicker not intended for account holder (i.e. passengers)
        this.pickerRules = this.isRequired ? [this.rules.required, this.rules.date] : [this.rules.date];
        if (this.isBirthdate)
        {
            var rule = this.isAccountHolder ? this.rules.birthdayOfAccountOwnerInRange : this.rules.birthdayInRange;
            this.pickerRules.push(rule)
        }

        this.activePicker = this.isBirthdate  ? 'YEAR' : 'DATE';
        this.updateDate();
    },
    data: () => ({
        txt_date: null,
        txt_minDate: null,
        txt_label: "",
        txt_isRequired: false,
        txt_isReadOnly: false,
        txt_isDisabled: false,
        txt_isBirthdate: false,
        dateFormatted: null,
        menu: false,
        activePicker: '',
        pickerRules: [],
        age_limit: 0
    }),
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = this.isBirthdate  ? 'YEAR' : 'DATE'))
        },
        date: {
            handler() {
                this.updateDate();
            }
        },
        isReadOnly: {
            deep: true,
            handler() {
                this.txt_isReadOnly = this.isReadOnly;
            }
        },
        isDisabled: {
            deep: true,
            handler() {
                this.txt_isDisabled = this.isDisabled;
            }
        },
        txt_date: 'onValueChanged',
    },
    methods:
    {
        updateDate()
        {
            this.txt_date = this.date;
            this.dateFormatted = this.formatDate(this.txt_date);
        },
        onValueChanged()
        {
            this.dateFormatted = this.formatDate(this.txt_date)
            this.$emit('changed', this.txt_date);
        }
    }
}
</script>
<style>
.hoverable-img 
{
    border-radius: 0px !important;
    cursor: pointer;
}
</style>
<style scoped>
.v-menu__content.theme--dark.menuable__content__active
{
    box-shadow: none !important;
}
</style>