<template>
   <!-- Voucher -->
    <div class="pb-3">
        <v-row no-gutters>
            <v-col cols="12" class="px-0">
                <span class="header-title font-weight-medium">Voucher</span>
            </v-col>
        </v-row>
        <v-row no-gutters class="voucher-container">
            <v-col cols="12" class="ma-auto py-2 px-0">
                <v-text-field
                    v-model="orderDetails.voucherCode"
                    label="Typ hier uw voucher code"
                    :hint="voucherCodeHint"
                    persistent-hint
                    outlined>
                    <template v-slot:append>
                        <v-fade-transition leave-absolute>
                            <template>
                                <v-icon v-if="isValid && hasValidated"
                                    dark right color="green darken-2">mdi-check-circle
                                </v-icon>
                                <v-icon v-if="!isValid && hasValidated" color="red">
                                    mdi-alert-circle
                                </v-icon>
                            </template>
                        </v-fade-transition>
                    </template>
                    <template v-slot:append-outer class="ml-2 mt-1">
                        <v-btn color="primary" block :width="120"
                            :disabled="!hasVoucherCode || loading"
                            :loading="loading"
                            @click="validateVoucher">
                            Valideer 
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MenuHelperMixin from "@/mixins/menu-helper.mixin";

export default {
    name: 'Voucher',
    props: {
        accountId: String,
        roadtrip: Object,
        orderDetails: Object,
        category: String,
    },
    mixins: [MenuHelperMixin],
    data: () => ({
        loading: false,
        isValid: false,
        hasValidated: false,
        voucherCodeHint: ''
    }),
    computed: {
        hasVoucherCode() {
            return this.orderDetails.voucherCode != '';
        }
    },
    methods: 
    {
        validateVoucher()
        {
            this.voucherCodeHint = '';
            this.hasValidated = false;
            this.loading = true;
            let request =
            {
                voucherCode: this.orderDetails.voucherCode,
                originalPrice: this.orderDetails.originalAmountPending,
                roadtripId: this.roadtrip.roadtripId,
                category: this.getProductCategoryId(this.category)
            }

            if (this.accountId != null)
            {
                request.accountId = this.accountId;
            }

            this.$store.dispatch('storeModule/validateVoucher', request)
            .then(data => {
                this.$set(this.orderDetails, 'voucherInfo', data);
                this.orderDetails.setDiscount();
                this.loading = false;
                this.isValid = data.errorMessage == "";

                if (this.isValid)
                {
                    this.voucherCodeHint = `Voucher code '${request.voucherCode}' toegevoegd.`;
                }
                else
                {
                    this.$store.commit('dialog/showDialog', data.errorMessage);
                }


                this.hasValidated = true;
                
            })
            .catch(() => {
                this.isValid = false;
                this.loading = false;
                this.hasValidated = true;
                this.$store.commit('dialog/showDialog', "Deze voucher is niet geldig.");
            });


        }
    }
}
</script>
<style>
.voucher-container .v-input__append-outer
{
    margin-top: 5px !important;
}

.voucher-discount,
.voucher-container .v-messages__wrapper .v-messages__message
{
    color: orange;
}
</style>