import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";

export default {
    mixins: [FormatMixin, MenuHelperMixin],
    methods: 
    {
        getTagColor(type)
        {
            switch(type)
            {
                case "Porsche":
                    return "red";
                case "Volvo":
                    return "blue";
                case "BMW":
                    return "teal";
                default:
                    return "orange";
            }
        },

        mapRoadtripData(data)
        {
            let roadtrip = data;

            roadtrip.date = this.getDateOnly(roadtrip.tripDate);
            roadtrip.label = this.getRoadtripLabel(roadtrip.roadtripLabel);
            roadtrip.labelColor = this.getTagColor(roadtrip.label);

            return roadtrip;
        }
       
    }
}