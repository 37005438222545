import { Car } from './Car';
import { Address } from './Address';
import { Driver } from './Driver';
import { VoucherTypeEnum } from "@/enums/VoucherTypeEnum";

export class OrderDetail {
    originalAmountPending = 0;
    totalAmountAfterDiscount = 0;
    isPaid = false;
    acceptTerms = false;
    isGuest = false;
    isNewAccount = false;
    registeredPassengers = [];
    hasReachedOverview = false;
    voucherCode = '';
    discount = 0;

    car = new Car();
    address = new Address();
    driver = new Driver();

    setDiscount()
    {
        if (!this.voucherInfo) return;

        this.discount = this.voucherInfo.voucherType == VoucherTypeEnum.PERCENTAGE 
            ? this.originalAmountPending * this.voucherInfo.voucherAmount 
            : this.voucherInfo.voucherAmount;
    }
}