<template>
    <v-stepper-content step="5" class="py-0 px-2 order-overview-loggedout">
        <!-- Login Details -->
        <div class="">
            <span>Bekijk en controleer hieronder de gegevens die u heeft ingevuld. Als alles correct is kunt u betalen.</span>
            <v-row no-gutters>
                <v-col cols="12" class="header px-0">
                    <span class="header-title font-weight-medium">Logingegevens</span>
                    <v-spacer></v-spacer>
                    <div>
                        <img class="edit-icon mt-3" :src="editIcon" alt="Edit" @click="backToStep(1)" />
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto pt-2 px-0">
                    Email
                </v-col>
                <v-col cols="6" class="ma-auto pt-2 px-0">
                    <div>{{orderDetails.driver.email}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="orderDetails.isNewAccount">
                <v-col cols="6" class="ma-auto px-0">
                    Password
                </v-col>
                <v-col cols="6" class="ma-auto px-0">
                    <div>
                        <v-text-field
                            class="password-overview"
                            :rules="[rules.password]"
                            color="primary"
                            :type="'password'"
                            hide-details=""
                            v-model="orderDetails.driver.password"
                            readonly flat disabled
                            required>
                        </v-text-field>

                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- Person Details -->
        <div class="pb-3">
            <v-row no-gutters>
                <v-col cols="12" class="header px-0">
                    <span class="header-title font-weight-medium">Gegevens bestuurder</span>
                    <v-spacer></v-spacer>
                    <div>
                        <img class="edit-icon mt-3" :src="editIcon" alt="Edit" @click="backToStep(1)" />
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="pb-3 pt-1">
                    <div>Deze gegevens zijn bij ons bekend:</div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider color="white"></v-divider>
                    </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="ma-auto py-2 px-0">
                    <div>
                        <v-list-item class="px-0">
                            <v-list-item-avatar width="60" height="60">
                                <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black">{{getPersonInitials(orderDetails.driver.firstname, orderDetails.driver.lastname)}}</div>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                                <div class="d-flex">
                                    <div class="pr-3 subtitle-1">{{fullname(orderDetails.driver)}}<span v-if="orderDetails.driver.phone">{{` (${orderDetails.driver.phone})`}} </span></div>
                                </div>
                                <div class="d-flex">
                                    <div class="pr-3 subtitle-1">{{formatDateWithoutTime(orderDetails.driver.birthdate)}}</div>
                                </div>
                                <div class="d-flex">
                                    <v-checkbox v-model="orderDetails.driver.hasLunch" class="ma-0 pt-0" :hide-details="true" v-if="roadtrip.packedLunchPrice != 0">
                                        <template v-slot:label>
                                            <div>
                                            Lunchpakket
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox v-model="orderDetails.driver.hasDinner" class="ml-5 ma-0 pt-0" :hide-details="true" v-if="roadtrip.dinnerPrice != 0">
                                        <template v-slot:label>
                                            <div>
                                            Diner
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <div class="pb-3">
                            Allergieën en voorkeuren: <span class="text-lowercase">{{getFoodRestrictions(orderDetails.driver.allergies)}}</span>
                        </div>
                        <v-divider color="white"></v-divider>
                    </div>
                </v-col>
            </v-row>
        </div>
        
        <!-- Passagiers -->
        <v-row no-gutters>
            <v-col cols="12" class="header px-0">
                <span class="header-title font-weight-medium">Passagiers</span>
                <v-spacer></v-spacer>
                <div>
                    <img class="edit-icon" :src="editIcon" alt="Delete" @click="backToStep(2)" />
                </div>
            </v-col>
        </v-row>
         <div class="pb-3">
            <v-row no-gutters>
            <v-col cols="12" class="pb-3 pt-1">
                <div>{{ orderDetails.registeredPassengers.length == 0 
                    ? `U heeft geen passagiers toegevoegd`
                    : `U rijdt mee met de volgende passagiers:`}}
                </div>
            </v-col>
            <v-col cols="12">
                <v-divider color="white"></v-divider>
            </v-col>
            </v-row>
            <template v-for="(item, index) in orderDetails.registeredPassengers">
                <v-row no-gutters :key="index">
                    <v-col cols="12" class="header px-0 ma-0">
                        <v-list-item class="px-0 pt-1">
                            <v-list-item-avatar width="60" height="60">
                                <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black" @click="setActiveItem()">{{getPersonInitials(item.firstname, item.lastname)}}</div>
                            </v-list-item-avatar>
                            <v-list-item-content class="pb-0">
                                <div class="d-flex mb-0">
                                    <div class="pr-3 subtitle-1">{{fullname(item)}}<span v-if="item.phone">{{` (${item.phone})`}} </span></div>
                                </div>
                                <div class="d-flex">
                                    <div class="pr-3">{{formatDateWithoutTime(item.birthdate)}}</div>
                                </div>
                                <div class="d-flex">
                                    
                                    <v-checkbox v-model="item.hasLunch" class="ma-0 pt-0" :hide-details="true" v-if="roadtrip.packedLunchPrice != 0">
                                        <template v-slot:label>
                                            <div>
                                            Lunchpakket
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox v-model="item.hasDinner" class="ml-5 ma-0 pt-0" :hide-details="true" v-if="roadtrip.dinnerPrice != 0">
                                        <template v-slot:label>
                                            <div>
                                            Diner
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12">
                        <div class="pb-3">
                            Allergieën en voorkeuren: <span class="text-lowercase">{{getFoodRestrictions(item.allergies)}}</span>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider color="white"></v-divider>
                    </v-col>
                </v-row>
            </template>
        </div>

        <!-- Voertuig -->
        <div class="pb-3">
            <v-row no-gutters>
                <v-col cols="12" class="header px-0">
                    <span class="header-title font-weight-medium">Voertuig</span>
                    <v-spacer></v-spacer>
                    <div>
                        <img class="edit-icon" :src="editIcon" alt="Delete" @click="backToStep(3)" />
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="pb-3 pt-1">
                        <div>U rijdt mee met het volgende voertuig:</div>
                </v-col>
                <v-col cols="12">
                    <v-divider color="white"></v-divider>
                </v-col>
                <v-col cols="12" class="header px-0 ma-0">
                    <v-list-item class="px-0 pt-1">
                        <v-list-item-avatar width="60" height="60">
                            <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black">{{getVehicleMake(orderDetails.car.make).charAt(0)}}</div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="`${getVehicleMake(orderDetails.car.make)} ${orderDetails.car.model} (${orderDetails.car.year})`" class="subtitle-1 pb-2"></v-list-item-title>
                            <div> {{ getCarDetails() }} </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12">
                    <div class="pb-3">{{`Kenteken: ${orderDetails.car.licensePlate}`}}</div>
                </v-col>
            </v-row>
            <v-divider color="white"></v-divider>
        </div>

        <!-- Address -->
        <div class="pb-3">
            <v-row no-gutters>
                <v-col cols="12" class="header px-0">
                    <span class="header-title font-weight-medium">Adresgegevens</span>
                    <v-spacer></v-spacer>
                    <div>
                        <img class="edit-icon mt-3" :src="editIcon" alt="Delete" @click="backToStep(4)" />
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    Straatnaam
                </v-col>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    <div>{{orderDetails.address.street}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    Huisnummer
                </v-col>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    <div>{{orderDetails.address.housenr}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    Postcode
                </v-col>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    <div>{{orderDetails.address.postcode}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    Plaatsnaam
                </v-col>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    <div>{{orderDetails.address.city}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    Land
                </v-col>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    <div>{{getCountryName(orderDetails.address.country)}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    Bedrijfsnaam
                </v-col>
                <v-col cols="6" class="ma-auto py-2 px-0">
                    <div>{{orderDetails.address.company}}</div>
                </v-col>
            </v-row>
        </div>

        <!-- Extra information -->
        <OrderNotes :orderDetails="orderDetails" />

        <!-- Voucher -->
        <Voucher 
            :roadtrip="roadtrip" 
            :orderDetails="orderDetails" 
            :category="'Roadtrip'"/>
     
        <v-form :ref="'stepForm'" lazy-validation>
            <TermsAndConditions :data="orderDetails" />
        </v-form>

        <v-btn color="primary" block class="mt-3" :disabled="orderDetails.originalAmountPending == 0"
            @click="validateOrder">
            Betalen
        </v-btn>
    </v-stepper-content>
</template>
<script>
module.exports = {
    props: {
        order: Function,
        rules: Object,
        backToStep: Function,
        getFuelType: Function,
        getCountryName: Function,
        getFoodRestrictions: Function,
        getPersonInitials: Function,
        getVehicleMake: Function,
        formatDateWithoutTime: Function,
        isElectricCar: Function,
        roadtrip:Object,
        orderDetails: Object
    },
    beforeCreate: function () {
        this.$options.components.Voucher = require('@/components/roadtrip/signup/Voucher').default
        this.$options.components.OrderNotes = require('@/components/roadtrip/signup/OrderNotes').default,
        this.$options.components.TermsAndConditions = require('@/components/common/TermsAndConditions').default
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        valid: true,
        editPersonDetails: false,
        editAddress: false,
    }),
    methods:
    {
        fullname(item)
        {
            if(item == null)
            {
                return "";
            }

            return `${item.firstname} ${item.lastname}`;
        },
        getCarDetails()
        {
            let car = this.orderDetails.car;
            let electricInfo = this.isElectricCar(car.fuelType) ? "" : `${car.engineCapacity} | ${car.cylinders} cilinder | `;
            return `${electricInfo}${this.getFuelType(car.fuelType).replace('_', ' ')} | ${car.power} pk`; 
        },
        validateOrder()
        {
            let v = this.$refs.stepForm.validate()
            if (v) {
                this.order();
            }
        },
        
    }
}
</script>
<style>
.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.header
{
    display: inline-flex;
}

.initials-avatar
{
    background: white;
    color: #181717;
    font-size: 26px;
    cursor: pointer;
}

.main-content .roadtrip-aanmelden.onStepMode  .v-input.password-overview .v-input__slot:before
{
   border: none !important;
}

.order-overview-loggedout  .header {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}
</style>