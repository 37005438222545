<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div v-if="!isLoading" class="roadtrip-aanmelden onStepMode" :class="{ 
            'allowLunch': roadtrip.packedLunchPrice  > 0, 
            'allowDinner': roadtrip.dinnerPrice > 0 , 
            'hasDiscount': orderDetails.discount > 0 }">
            <v-container class="py-4">
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2" class="d-flex px-2">
                        <span class="display-1">{{roadtrip.tripName}}</span>
                        <span class="body-1 tag-wrap">
                            <v-alert class="tag mx-3 my-1 pa-0 text-center red" 
                                :class="`${roadtrip.labelColor}`"
                                text--white
                                width="100"
                                dense
                                prominent
                                text>{{roadtrip.label}}</v-alert>
                        </span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2" class="text-start px-2 pb-4">{{formatDayMonthYear(roadtrip.date)}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2">
                        <v-stepper v-model="step">

                            <v-stepper-header v-show="step !== 5">
                                <template v-for="(item, key) in steps">
                                <!-- all siblings must have the same :key or v-bind:key-->
                                <span :class="{hide : item == 5 }" :key="item">
                                    <v-stepper-step :step="item" :complete="step > item" >
                                </v-stepper-step>
                                </span>
                                
                                <v-divider v-if="key !== steps.length - 1" :key="`div-${key}`" v-show="item < 4"></v-divider>
                                </template>
                            </v-stepper-header>

                            <v-stepper-items>
                                <DriverDetails 
                                    :roadtrip="roadtrip"
                                    :orderDetails="orderDetails"
                                    :isPhoneNumberKey="isPhoneNumberKey"
                                    :isNonNumeric="isNonNumeric"
                                    :rules="rules"
                                    :next="next" />

                                <PassengerDetails 
                                    :roadtrip="roadtrip"
                                    :orderDetails="orderDetails"
                                    :formatDateWithoutTime="formatDateWithoutTime"
                                    :getFoodRestrictions="getFoodRestrictions"
                                    :getPersonInitials="getPersonInitials"
                                    :isPhoneNumberKey="isPhoneNumberKey"
                                    :isNonNumeric="isNonNumeric"
                                    :rules="rules"
                                    :next="next" 
                                    :previous="previous" />

                                <CarDetails 
                                    :fuelTypes="fuelTypes"
                                    :orderDetails="orderDetails"
                                    :rules="rules"
                                    :next="next" 
                                    :previous="previous" />

                                <AddressDetails 
                                    :address="orderDetails.address"
                                    :next="next" 
                                    :previous="previous" />

                                <OrderOverview 
                                    :roadtrip="roadtrip"
                                    :orderDetails="orderDetails"
                                    :rules="rules"
                                    :getFuelType="getFuelType"
                                    :getCountryName="getCountryName"
                                    :isElectricCar="isElectricCar"
                                    :getFoodRestrictions="getFoodRestrictions"
                                    :getPersonInitials="getPersonInitials"
                                    :formatDateWithoutTime="formatDateWithoutTime"
                                    :getVehicleMake="getVehicleMake"
                                    :backToStep="backToStep"
                                    :order="order" />
                            </v-stepper-items>
                        </v-stepper>
                    </v-col>
                </v-row>
            </v-container>
            <BestellingInfo :orderDetails="orderDetails" :roadtrip="roadtrip" :existingOrders="existingOrders" />
        </div>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import LoadingIcon from '@/components/common/LoadingIcon';

// Steps to Sign up Roadtrip
import DriverDetails from '@/components/roadtrip/signup/DriverDetails';
import PassengerDetails from '@/components/roadtrip/signup/PassengerDetails';
import CarDetails from '@/components/roadtrip/signup/CarDetails';
import AddressDetails from '@/components/roadtrip/signup/AddressDetails';
import OrderOverview from '@/components/roadtrip/signup/OrderOverview';

import BestellingInfo from '@/components/kalender/bestelling-info';

export default {
    name: 'AanmeldenLoggedOut',
    props: {
        orderDetails: Object,
        roadtrip: Object,
    },
    mixins: [DataMixin, RouteMixin, FormatMixin, GlobalMixin, MenuHelperMixin],
    components: 
    {
        DriverDetails,
        PassengerDetails,
        CarDetails,
        AddressDetails,
        OrderOverview,
        BestellingInfo,
        LoadingIcon
    },
    data: () => ({
        bannerImg: require('@/assets/album/Eifel Tour/eifel-tour-01.jpg'),
        steps: [1,2,3,4,5],
        step: 1,
        isLoading: false,
        existingOrders: {
            hasMainOrder: false,
            orderDetails: []
        },
    }),
    methods:
    {
        next() {
            if(this.orderDetails.hasReachedOverview)
            {
                this.step = 5;
                return;
            }

            this.step += 1;
            if (this.step == 5)
            {
                this.orderDetails.hasReachedOverview = true;
            }
        },
        previous() {
            this.step -= 1
        },
        backToStep(step){
            this.step = step;
        },
        order()
        {
            this.isLoading = true; 
            this.$store.dispatch('storeModule/placeOrderWithNewAccount', this.getRequest())
                .then(data => {
                    // redirect to mollie
                    window.location = data;
            })
            .catch(error => { 
                this.$store.commit('dialog/showDialog', error);
                this.isLoading = false; 
            });
           
        },
        getRequest()
        {
            var passengerOrderDetails = [];
            this.orderDetails.registeredPassengers.forEach(passenger => {
                    passengerOrderDetails.push({
                        hasBreakfast: passenger.hasBreakfast,
                        hasPackedLunch: passenger.hasLunch,
                        hasDinner: passenger.hasDinner,
                        hasHotel: passenger.hasHotel,
                        coregisteredPerson: {
                            accountId: passenger.personId,
                            firstname: passenger.firstname,
                            lastName: passenger.lastname,
                            dateOfBirth: passenger.birthdate,
                            phoneNumber: passenger.phone,
                            foodRestrictions: passenger.allergies
                        }
                    });
                });

            var loginInformation = {
                emailAddress:   this.orderDetails.driver.email,
                accountOrigin:  this.getAccountOriginId("New")
            };

            if (this.orderDetails.isNewAccount)
            {
                loginInformation.password = this.orderDetails.driver.password;
            }

            var request = {
                roadpursuitRegistrationInformation: {
                    loginInformation: loginInformation,
                    basicPersonInformation: {
                        firstname:      this.orderDetails.driver.firstname,
                        lastName:       this.orderDetails.driver.lastname,
                        dateOfBirth:    this.orderDetails.driver.birthdate,
                        phoneNumber:    this.orderDetails.driver.phone
                    },
                    addressInformation: {
                        street:         this.orderDetails.address.street,
                        houseNumber:    this.orderDetails.address.housenr,
                        zipCode:        this.orderDetails.address.postcode,
                        city:           this.orderDetails.address.city,
                        country:        this.orderDetails.address.country,
                        company:        this.orderDetails.address.company,
                    }
                },
                foodRestrictions:       this.orderDetails.driver.allergies,
                vehicleInformation: {
                    vehicleType:            this.orderDetails.car.vehicleType,
                    licensePlate:           this.orderDetails.car.licensePlate,
                    make:                   this.orderDetails.car.make,
                    model:                  this.orderDetails.car.model,
                    year:                   this.orderDetails.car.year,
                    engineCapacity:         this.orderDetails.car.engineCapacity,
                    cylinders:              this.orderDetails.car.cylinders,
                    fuelType:               this.orderDetails.car.fuelType,
                    power:                  this.orderDetails.car.power,
                    participatedTrips:      0,
                    participatedDistance:   0
                },
                orderInformation: {
                    productId: this.roadtrip.productId,
                    note: this.orderDetails.notes,
                    voucherCode: this.orderDetails.voucherCode,
                    accountOwnerOrderDetails: {
                        hasBreakfast: this.orderDetails.driver.hasBreakfast,
                        hasPackedLunch: this.orderDetails.driver.hasLunch,
                        hasDinner: this.orderDetails.driver.hasDinner,
                        hasHotel: this.orderDetails.driver.hasHotel
                    },
                    coregisteredOrderDetails: passengerOrderDetails
                }
            }

            console.log(request);
            return request;
        }
    }
}
</script>