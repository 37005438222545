export class Car {
    street = '';
    id = '';
    licensePlate = '';
    make = 0;
    model = '';
    year = 0;
    engineCapacity = 0;
    cylinders = 0;
    power = 0;
    fuelType = 0;
    participatedDistance = 0;
}