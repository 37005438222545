<template>
    <v-stepper-content step="4" class="py-0 px-2">
        <p class="headline">Adresgegevens</p>

        <v-form :ref="'stepForm'" lazy-validation>
            <AddressBase :address="address" />
        </v-form>

        <v-btn color="primary" block
            @click="validate">
            Controleer bestelling
        </v-btn>
        <v-row class="justify-center text-center" no-gutters>
            <v-card-actions>
                <v-container>
                    <v-row dense>
                            <v-col cols="12">
                            Foutje gemaakt?
                            <span class="link" @click="previous">Ga terug.</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-row>

    </v-stepper-content>
</template>

<script>
module.exports = {
    props: {
        next: Function,
        previous: Function,
        address: Object
    },
    data: () => ({
    }),
    beforeCreate: function () {
        this.$options.components.AddressBase = require('@/components/common/AddressBase').default
    },
    methods:
    {
        validate()
        {
            let v = this.$refs.stepForm.validate()
            if (v) {
                this.next();
            }
        }
    }
}
</script>
