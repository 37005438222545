<template>
    <v-container fluid class="allergy-container">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-autocomplete
                    v-model="txt_allergies"
                    :items="foodRestrictions"
                    label=" Allergieën en voedselvoorkeuren"
                    multiple
                    item-text="item2"
                    item-value="item1"
                    hide-details
                    return-object
                    deletable-chips
                    @change="onValueChanged"
                    chips>
                </v-autocomplete>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
export default {
    name: 'FoodRestrictionDropdown',
    props: 
    {
        allergies: Array
    },
    beforeMount()
    {
        this.mapAllergies();
    },
    mixins: [MenuHelperMixin],
    data() {
        return {
            showOptions: false,
            txt_allergies: [],
        }
    },
    watch: {
        allergies: {
            handler() {
                this.mapAllergies();
            }
        },
        showOptions: {
            handler() {
                this.$emit('showMenu', this.showOptions);
            }
        }
    },
    computed: {
        ...mapState({
            foodRestrictions: state => state.menuHelper.foodRestrictions
        })
    },
    methods:
    {
        mapAllergies()
        {
            this.txt_allergies = this.foodRestrictions.filter(x => this.allergies.includes(x.item1));
        },
        onValueChanged()
        {
            this.$emit('changed', this.txt_allergies.map(x => x.item1));
        }
    }
}
</script>
<style>
.allergy-container .v-text-field .v-label--active {
    top: 0px;
}
</style>