<template>
    <v-footer
      v-bind="localAttrs"
      :padless="true"
      class="bestelling-footer">
        <div class="bottom-section">
            <v-container class="pb-0 pt-2 px-0 first-container">
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2">
                        <v-container class="py-0 px-5">
                            <v-row no-gutters v-if="orderDetails.discount > 0">
                                <v-col cols="9">
                                    <div class="subtitle-1"><strong>Totaalprijs:</strong></div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="subtitle-1 text-end"><strong>{{getCurrency(orderDetails.totalAmountAfterDiscount)}}</strong></div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-if="orderDetails.discount > 0">
                                <v-col cols="9">
                                    <div class="subtitle-1 voucher-discount">
                                        {{ getDiscountInfo() }}
                                    </div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="subtitle-1 text-end voucher-discount">{{getCurrency(orderDetails.discount)}}</div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="9">
                                    <div class="subtitle-1">{{ existingOrders.hasMainOrder ? `Uw wijziging:` : `Uw bestelling:`}}</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="subtitle-1 text-end">{{getCurrency(originalAmountPending)}}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider color="white"></v-divider>
                        <v-container class="pt-0 px-5">
                            <v-row no-gutters v-if="!existingOrders.hasMainOrder">
                                <v-col cols="1">
                                    <div class="">1x</div>
                                </v-col>
                                <v-col cols="9">
                                    <div class="">Basispakket (auto + bestuurder)</div>
                                </v-col>
                                <v-col cols="2">
                                    <div class="text-end">{{getCurrency(roadtrip.mainPrice)}}</div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-if="existingOrders.hasMainOrder">
                                <v-col cols="1">
                                    <div class="">0x</div>
                                </v-col>
                                <v-col cols="9">
                                    <div class="">Basispakket (auto + bestuurder)</div>
                                </v-col>
                                <v-col cols="2">
                                    <div class="text-end">{{getCurrency(0)}}</div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="1">
                                    <div class="">{{passengerCount}}x</div>
                                </v-col>
                                <v-col cols="9">
                                    <div class="">Passagier</div>
                                </v-col>
                                <v-col cols="2">
                                    <div class="text-end">{{getCurrency(passengerTotalPrice)}}</div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-if="roadtrip.packedLunchPrice != 0">
                                <v-col cols="1">
                                    <div class="">{{lunchPakketCount}}x</div>
                                </v-col>
                                <v-col cols="9">
                                    <div class="">Lunchpakket</div>
                                </v-col>
                                <v-col cols="2">
                                    <div class="text-end">{{getCurrency(lunchTotalPrice)}}</div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-if="roadtrip.dinnerPrice != 0">
                                <v-col cols="1">
                                    <div class="">{{dinerCount}}x</div>
                                </v-col>
                                <v-col cols="9">
                                    <div class="">Diner</div>
                                </v-col>
                                <v-col cols="2">
                                    <div class="text-end">{{getCurrency(dinnerTotalPrice)}}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-footer>

</template>

<script>
import FormatMixin from "@/mixins/format.mixin";
import { VoucherTypeEnum } from "@/enums/VoucherTypeEnum";

export default {
    name: 'BestellingInfo',
    mixins: [FormatMixin],
    props: {
        orderDetails: Object,
        roadtrip: Object,
        existingOrders: Object,
    },
    computed: 
    {
        localAttrs () {
            const attrs = {
                fixed: true
            }
            
            return attrs;
        },
        passengers()
        {
            let passengersNotFromMainOrder = this.orderDetails.registeredPassengers.filter(x => !this.existingOrders.orderDetails.map(y => y.personId).includes(x.personId))
            let passengers = this.existingOrders.hasMainOrder ? passengersNotFromMainOrder : this.orderDetails.registeredPassengers;
            return passengers;
        },
        passengerCount()
        {
            return this.passengers.length;
        },
        passengerTotalPrice(){
            return this.passengerCount * this.roadtrip.passengerPrice;
        },
        lunchPakketCount()
        {
            // iterate through current ordetails and add only lunch count if it is not in the main order
            let totalNewLunchesOfPassengers = 0;
            this.orderDetails.registeredPassengers.filter(x => x.hasLunch).forEach(passenger => {
                // check from main order
                let mainOrder = this.existingOrders.orderDetails.find(x => x.personId == passenger.personId);
                let lunchCount = (mainOrder == null && passenger.hasLunch) 
                                || (mainOrder != null && !mainOrder.hasPackedLunch && passenger.hasLunch) ? 1 : 0;
                totalNewLunchesOfPassengers = totalNewLunchesOfPassengers + lunchCount;
            });

            let driverLunchInMainOrder = false;
            if (this.existingOrders.hasMainOrder)
            {
                // check in the main order if driver ordered lunch
                let driver = this.existingOrders.orderDetails.find(x => x.personId == this.orderDetails.driver.personId);
                if(driver != null)
                {
                    driverLunchInMainOrder = driver.hasPackedLunch;
                }
            }

            // don't count driver lunch if it was already part of main order
            let driverLunchCount = driverLunchInMainOrder ? 0 : (this.orderDetails.driver.hasLunch ? 1 : 0);
            let lunchCount = totalNewLunchesOfPassengers + driverLunchCount;
            return lunchCount;
        },
        lunchTotalPrice(){
            return this.lunchPakketCount * this.roadtrip.packedLunchPrice;
        },
        dinerCount()
        {
            // iterate through current ordetails and add only lunch count if it is not in the main order
            let totalNewDinnerOfPassengers = 0;
            this.orderDetails.registeredPassengers.filter(x => x.hasDinner).forEach(passenger => {
                // check from main order
                let mainOrder = this.existingOrders.orderDetails.find(x => x.personId == passenger.personId);
                let dinnerCount = (mainOrder == null && passenger.hasDinner) 
                                || (mainOrder != null && !mainOrder.hasDinner && passenger.hasDinner) ? 1 : 0;
                totalNewDinnerOfPassengers = totalNewDinnerOfPassengers + dinnerCount;
            });

            let driverDinnerInMainOrder = false;
            if (this.existingOrders.hasMainOrder)
            {
                // check in the main order if driver ordered lunch
                let driver = this.existingOrders.orderDetails.find(x => x.personId == this.orderDetails.driver.personId);
                if(driver != null)
                {
                    driverDinnerInMainOrder = driver.hasDinner;
                }
            }

            // don't count driver lunch if it was already part of main order
            let driverDinnerCount = driverDinnerInMainOrder ? 0 : (this.orderDetails.driver.hasDinner ? 1 : 0);
            let dinnerCount = totalNewDinnerOfPassengers + driverDinnerCount;
            return dinnerCount;
        },
        dinnerTotalPrice(){
            return this.dinerCount * this.roadtrip.dinnerPrice;
        },
        originalAmountPending()
        {
            let totalAmount = this.passengerTotalPrice + this.lunchTotalPrice + this.dinnerTotalPrice;
            if (!this.existingOrders.hasMainOrder)
            {
                totalAmount = totalAmount + this.roadtrip.mainPrice;
            }
            
            this.setOriginalAmount(totalAmount);

            // re-calculate discount again
            this.orderDetails.setDiscount();
            let discountedAmount = totalAmount - this.orderDetails.discount;
            discountedAmount = discountedAmount < 0 ? 0 : discountedAmount;
            this.setTotalAmountAfterDiscount(discountedAmount);

            return totalAmount;
        },
    },
    data: () => ({
        voucherValidated: false
    }),
    methods: {
        getDiscountInfo()
        {
            if (!this.orderDetails.voucherInfo) return '';

            const discountInfo = this.orderDetails.voucherInfo.voucherType == VoucherTypeEnum.PERCENTAGE 
                ? `${this.orderDetails.voucherInfo.voucherAmount * 100}%`
                : this.getCurrency(this.orderDetails.voucherInfo.voucherAmount);

            return `Korting (- ${discountInfo})`;
        },
        setOriginalAmount(amount)
        {
            this.$set(this.orderDetails, 'originalAmountPending', amount);
        },
        setTotalAmountAfterDiscount(amount)
        {
            this.$set(this.orderDetails, 'totalAmountAfterDiscount', amount);
        },
        getPassengerFromMainOrder(personId)
        {
            return this.existingOrders.orderDetails.find(x => x.personId == personId);
        },
    }
  }
</script>
<style scoped>
.v-footer.bestelling-footer
{
    background-color: rgb(24 23 23 / 75%) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top: 1px solid #2e2e2ea3;
    box-shadow: 0px 0 24px rgb(0 0 0 / 70%) !important;
}
</style>
