<template>
    <div>
        <v-text-field v-model="address.street"
            label="Straatnaam*"
            :rules="[rules.required, rules.maxLength(address.street, 40)]"
            required />

        <v-text-field v-model="address.housenr"
            label="Huisnummer*"
            :rules="[rules.required, rules.maxLength(address.housenr, 15)]"
            required />

        <v-text-field v-model="address.postcode"
            label="Postcode*"
            :rules="[rules.required, rules.maxLength(address.postcode, 10)]"
            required />
        
        <v-text-field v-model="address.city"
            label="Plaatsnaam*"
            :rules="[rules.required, rules.maxLength(address.city, 40)]"
            required />

        <CountryDropdown :country="address.country" @changed="address.country = $event" class="mt-5" />

        <v-text-field v-model="address.company"
            label="Bedrijfsnaam" 
            :rules="[rules.maxLength(address.company, 40)]"
            />
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import CountryDropdown from '@/components/common/options/CountryDropdown';

export default {
    name: 'AddressBase',
    props: {
        address: Object
    },
    components: 
    {
        CountryDropdown
    },
    mixins: [DataMixin],
}
</script>
