<template>
    <v-stepper-content step="3" class="py-0 px-2">
        <CarForm 
            :index="0"
            :licensePlate="orderDetails.car.licensePlate"
            :vehicleType="orderDetails.car.vehicleType"
            :make="orderDetails.car.make"
            :model="orderDetails.car.model"
            :year="orderDetails.car.year"
            :engineCapacity="orderDetails.car.engineCapacity"
            :cylinders="orderDetails.car.cylinders"
            :power="orderDetails.car.power"
            :fuelType="orderDetails.car.fuelType"
            :id="orderDetails.car.id"
            :isActive="true"
            :isNew="true"
            :isOrderForm="true"
            @create="createCar"
            @cancel="previous" />
    </v-stepper-content>
</template>

<script>

module.exports = {
    props: {
        next: Function,
        previous: Function,
        rules: Object,
        orderDetails: Object,
        fuelTypes: Array,
    },
    beforeCreate: function () {
        this.$options.components.FuelTypeDropdown = require('@/components/common/options/FuelTypeDropdown').default;
        this.$options.components.VehicleMakeDropdown = require('@/components/common/options/VehicleMakeDropdown').default;
        this.$options.components.CarForm = require('@/components/dashboard/CarForm').default;
    },
    data: () => ({
        valid: true
    }),
    methods:
    {
        createCar(index, params)
        {
            this.orderDetails.car.id = params.id;
            this.orderDetails.car.vehicleType = params.vehicleType;
            this.orderDetails.car.licensePlate = params.licensePlate;
            this.orderDetails.car.make = params.make;
            this.orderDetails.car.model = params.model;
            this.orderDetails.car.year = params.year;
            this.orderDetails.car.engineCapacity = params.engineCapacity;
            this.orderDetails.car.cylinders = params.cylinders;
            this.orderDetails.car.power = params.power;
            this.orderDetails.car.fuelType = params.fuelType;
            // continue to next
            this.next();
        },
    }
}
</script>